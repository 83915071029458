///  $unit - %, example if $unit= % => width: 5{$unit} = width: 5%,
///  $step {number} (min value 0 to max int value) - is step for generation properties,
///  $min-range {number} - minvalue for generation,
///  $max-range {number} - maxvalue for generation,
///  $prefix - class name prefix, example, $prefix = "height", result = {height}-{step value} => height-50.
///  @example
///  ```text
///  @include generate-dimension(%, 10, 10, width);
///    .width-10 {
///      width: 10%;
///    }
///
///  @include generate-dimension(%, 5, 10, height);
///    .height-50 {
///      height: 50%;
///    }
///  ```
///  In HTML code, you can use already generated classes
///  @example
///  ```text
///     <div class="width-10"></div>
///  ```
///
///  Based on the most used indents from Bootstrap and Material Design.

@mixin generate-dimension($unit, $step, $class-name, $property) {
  $count: 0;
  $space-max: 1000;

  @while $count <= $space-max {
    .#{$class-name}-#{$count} {
      @each $subproperty in $property {
        #{$subproperty}: #{$count + $unit};
      }
    }
    $count: $count + $step;
  }
}

$property-list: (
        width (width) 1,
        height (height) 1,
);

@each $class-name, $property, $step in $property-list {
  @include generate-dimension(
          $unit: "px",
          $step: $step,
          $class-name: $class-name,
          $property: $property
  );
}

$property-list: (
        max-width (max-width) 1,
        max-height (max-height) 1,
);

@each $class-name, $property, $step in $property-list {
  @include generate-dimension(
          $unit: "px",
          $step: $step,
          $class-name: $class-name,
          $property: $property
  );
}

/// Custom classes
.width-auto {
  width: auto;
}

.height-auto {
  height: auto;
}
