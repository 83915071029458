@import "~node_modules/@angular/material/prebuilt-themes/indigo-pink.css";
/* You can add global styles to this file, and also import other style files */
/* Core Grid CSS */
@import "ag-grid-community/styles/ag-grid.css";
/* Quartz Theme Specific CSS */
@import "ag-grid-community/styles/ag-theme-quartz.css";

@import "~@ng-select/ng-select/themes/default.theme.css";

.ag-header-cell {
  height: 100% !important;
}

.ag-wrapper.ag-input-wrapper.ag-checkbox-input-wrapper {
  outline: 1px solid grey !important;
}

.ag-header.ag-header-allow-overflow .ag-header-row {
  overflow: hidden;
}

input {
  text-align: left !important;
}

.loader_main{
  position: fixed;
  left: 150px;
  top: 45px;
  z-index: 999;
  background: #ffffff9e !important;
  width: calc(100% - 150px);
  height: calc(100% - 45px);
  display: flex;
  justify-content: center;
  align-items: center;
}
.loader {
  z-index: 999;
  padding: 16px 30px;
  border: 1px solid #efefef;
  background: #fff;
}
